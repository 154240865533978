<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();

	import collapse from '$lib/components/Misc/Collapseable/collapse.js';

	export let open = false;
	export let lock = false;
	export let duration = 0.3;
	export let easing = 'ease-in-out';
	export let height = undefined;
	export let headerClass = '';
	export let bodyClass = '';

	function handleToggle() {
		if (lock) return;
		open = !open;

		if (open) dispatch('open');
		if (!open) dispatch('close');
	}
</script>

<div class="card" class:open aria-expanded={open} bind:clientWidth={height}>
	<div class="card-header {headerClass}" on:click|stopPropagation={handleToggle}>
		<slot name="header" />
	</div>
	<div class="card-body {bodyClass} hardware-accelate" use:collapse={{ open, duration, easing }}>
		<slot name="body" />
	</div>
</div>

<style>
	.card-header {
		cursor: pointer;
		user-select: none;
	}
	.card-body {
		-webkit-transform: translate3d(0, 0, 0);
		transition: -webkit-transform 0.5s ease;
	}
</style>
